import React, { useMemo, useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroll-component';

// Mui
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

// Core
import Skeleton from 'components/ProductCard/Skeleton';
import clsx from 'clsx';
import BusinessProducts from 'components/BusinessProductsComponent';
import { Switch, useMediaQuery, withStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { postActions } from 'states/Post';
import { useLocation } from 'react-router';
import { searchSelectors } from '../../states/Search';
import ProductListFilter from './Filter';
import { productActions, productSelectors } from '../../states/Products';

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 8,
      paddingRight: 8,
    },
  },
  title: {
    color: '#333333',
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: '0.5rem',
    marginTop: '0.5rem',

    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
    },
  },
  centerTitle: {
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  navigation: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  isBusinessBlock: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '150px',
    alignItems: 'center',
    '& p': {
      color: theme.palette.primary.blue,
      fontSize: '16px',
      lineHeight: '19px',
      textTransform: 'uppercase',
    },
  },
}));

function ProductList(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    title,
    products,
    fetchMore,
    hasMore,
    intl,
    messages,
    isTitleCenter,
    isFavoritePage,
  } = props;

  const location = useLocation();

  const isMobile = useMediaQuery('(max-width: 720px)');

  const [isBusinessProducts, setBusinessProducts] = useState(
    JSON.parse(localStorage.getItem('_ts:isBusinessProducts')),
  );
  const selectedCategory = useSelector(searchSelectors.selectedCategory);
  const selectedLocation = useSelector(searchSelectors.selectedLocation);
  const search = useSelector(searchSelectors.search);
  const currency = useSelector(searchSelectors.currency);
  const order = useSelector(searchSelectors.order);
  const gte = useSelector(searchSelectors.gte);
  const lte = useSelector(searchSelectors.lte);
  const nextKey = useSelector(productSelectors.nextKey);
  const options = useSelector(searchSelectors.options);
  const parameters = useSelector(searchSelectors.parameters);

  const [categoryParams, setCategoryParams] = useState(null);
  const [categoryParamsLoading, setCategoryParamsLoading] = useState(false);

  const fetchCategoryParams = useCallback(async () => {
    try {
      if (selectedCategory === 'all') return null;
      setCategoryParamsLoading(true);
      const data = await dispatch(
        postActions.getCategoryParameters(selectedCategory, true),
      );

      setCategoryParams(data);
      setCategoryParamsLoading(false);
      return data;
    } catch (error) {
      console.error(error);
      setCategoryParamsLoading(false);

      return null;
    }
  }, [dispatch, selectedCategory]);

  useEffect(() => {
    fetchCategoryParams();
  }, [fetchCategoryParams]);

  const loader = useMemo(
    () => (
      <Grid
        container
        spacing={2}
      >
        {new Array(8).fill(null).map((_, idx) => (
          <Grid
            key={idx}
            item
            xs={6}
            sm={4}
            md={3}
          >
            <Skeleton width={210} />
          </Grid>
        ))}
      </Grid>
    ),
    [],
  );

  const MySwitch = withStyles((theme) => ({
    switchBase: {
      color: '#9AA6B6',
      '&$checked': {
        color: theme.palette.primary.blue,
      },
      '&$checked + $track': {
        backgroundColor: theme.palette.primary.blue,
      },
    },
    checked: {},
    track: {},
  }))(Switch);

  const businessProdHandler = async () => {
    const params = {
      key: nextKey,
      location: selectedLocation !== 'all' ? selectedLocation : undefined,
      category: selectedCategory !== 'all' ? selectedCategory : undefined,
      ordering: ![null, '', '0'].includes(order) ? order : undefined,
      search: search || undefined,
      price__gte: gte && !lte ? gte : undefined,
      price__lte: lte && !gte ? lte : undefined,
      price__range: gte && lte ? `${gte},${lte}` : undefined,
      currency: currency || undefined,
      limit: 8,
      offset: 0,
    };

    try {
      if (isBusinessProducts) {
        setBusinessProducts(false);
        localStorage.setItem('_ts:isBusinessProducts', false);
        await dispatch(productActions.getProductList(params, false, false));
      } else {
        setBusinessProducts(true);
        localStorage.setItem('_ts:isBusinessProducts', true);
        await dispatch(productActions.getProductList(params, false, true));
      }
    } catch (error) {
      console.error('Error fetching product list:', error);
    }
  };

  const isMainPage = location.pathname === '/';

  const mappedParams = useMemo(() => {
    return parameters.map((p) =>
      Object.keys(p).reduce((acc, k) => {
        if (!k === 'from' || !k === 'to') {
          acc[k] = p[k];
        }

        if (p[k].length !== 0) {
          acc[k] = p[k];
        }

        return acc;
      }, {}),
    );
  }, [parameters]);

  useEffect(() => {
    const params = {
      key: nextKey,
      location: selectedLocation !== 'all' ? selectedLocation : undefined,
      category: selectedCategory !== 'all' ? selectedCategory : undefined,
      ordering: ![null, '', '0'].includes(order) ? order : undefined,
      search: search || undefined,
      price__gte: gte && !lte ? gte : undefined,
      price__lte: lte && !gte ? lte : undefined,
      price__range: gte && lte ? `${gte},${lte}` : undefined,
      currency: currency || undefined,
      options:
        options.length > 0
          ? options.map((option) => option.id).join(',')
          : undefined,
      parameters:
        mappedParams.length > 0
          ? JSON.stringify(mappedParams).replaceAll('},{', '}-{')
          : undefined,
      limit: 28,
    };
    dispatch(productActions.getProductList({ ...params, offset: 0 }));
    // eslint-disable-next-line
  }, []);
  return (
    <div className={classes.root}>
      <div className={classes.navigation}>
        {!isFavoritePage && (
          <>
            {categoryParams?.length > 0 && !isMobile && !isMainPage ? (
              <ProductListFilter
                isLoading={categoryParamsLoading}
                onlyLocation={categoryParams?.length < 2}
              />
            ) : (
              <Typography
                variant="h6"
                component="h4"
                className={clsx(classes.title, {
                  [classes.centerTitle]: isTitleCenter,
                })}
              >
                {title || intl.formatMessage({ ...messages.newProducts })}
              </Typography>
            )}
            <div className={classes.isBusinessBlock}>
              <Typography component="p">Business</Typography>
              <MySwitch
                checked={isBusinessProducts}
                onClick={businessProdHandler}
              />
            </div>
          </>
        )}
      </div>
      <InfiniteScroll
        dataLength={products.length}
        next={() => fetchMore(products.length, true)}
        hasMore={hasMore}
        loader={loader}
        scrollableTarget="scroller"
        style={{
          width: '100%',
          overflowX: 'hidden',
          overflowY: 'hidden',
        }}
      >
        <BusinessProducts
          intl={intl}
          products={products}
          isMyProfile={false}
          fetchMore={fetchMore}
          isFavoritePage={isFavoritePage}
        />
      </InfiniteScroll>
    </div>
  );
}

ProductList.propTypes = {
  products: PropTypes.array.isRequired,
  fetchMore: PropTypes.func,
  hasMore: PropTypes.bool,
  currency: PropTypes.string,
  title: PropTypes.string,
  intl: PropTypes.object,
  messages: PropTypes.object,
  isTitleCenter: PropTypes.bool,
  isFavoritePage: PropTypes.bool,
};

export default ProductList;
