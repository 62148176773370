import React from 'react';
import PropTypes from 'prop-types';
import { useStyles } from './styles';
import BusinessProduct from 'components/BusinessProduct';

const Index = (props) => {
  const {
    intl,
    products,
    setProducts,
    saveChanges,
    isSelect,
    selected,
    isMyProfile,
    withoutSwitch,
    fetchMore,
    isFavoritePage,
    catalogId,
    className,
    newProductsTab,
    onUser,
  } = props;

  const classes = useStyles();
  return (
    <div className={`${classes.root} ${className}`}>
      {products &&
        Object.keys(products).map((id) => (
          <div
            key={id}
            className={classes.rootProduct}
          >
            <BusinessProduct
              intl={intl}
              catalogId={catalogId}
              product={products[id]}
              setProducts={setProducts}
              userId={products[id]?.user}
              saveChanges={saveChanges}
              isSelect={isSelect}
              selected={selected}
              isMyProfile={isMyProfile}
              onUser={onUser}
              withoutSwitch={withoutSwitch}
              fetchMore={fetchMore}
              isFavoritePage={isFavoritePage}
              data={products}
              id={id}
              newProductsTab={newProductsTab}
            />
          </div>
        ))}
    </div>
  );
};

Index.propTypes = {
  intl: PropTypes.object,
  products: PropTypes.array.isRequired,
  setProducts: PropTypes.func,
  saveChanges: PropTypes.func,
  isSelect: PropTypes.bool,
  selected: PropTypes.func,
  isMyProfile: PropTypes.bool,
  withoutSwitch: PropTypes.bool,
  fetchMore: PropTypes.func,
  isFavoritePage: PropTypes.bool,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  newProductsTab: PropTypes.bool,
  checkProducts: PropTypes.object,
};

export default Index;
